import React from 'react';
import PropTypes from 'prop-types';
import { Box, AppBar, ClickAwayListener } from '@mui/material';

import NavBar from './navbar';
import TopBar from './topbar';
import './style.css';

const TinyLayout = ({ open, setOpen, handleOpen, children, userInfo }) => {
  const handleClickNav = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ClickAwayListener onClickAway={handleClickNav}>
        <Box>
          <Box className={open ? 'tinySlideBar' : 'tinySlideBarClosed'}>
            <NavBar open={open} handleOpen={handleOpen} tinyMode />
          </Box>
          <AppBar className={open ? 'topBar' : 'topBarClosed'}>
            <TopBar userInfo={userInfo} handleOpen={setOpen} />
          </AppBar>
        </Box>
      </ClickAwayListener>
      <Box className={open ? 'contentTiny' : 'contentTinyClosed'}>
        <Box sx={{ padding: '20px 15px' }}>{children}</Box>
      </Box>
    </Box>
  );
};

TinyLayout.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
  }).isRequired,
};

TinyLayout.defaultProps = {
  children: <div />,
};
export default TinyLayout;
