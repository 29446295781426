import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from './components/ui/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LayOut from './layouts/app';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component || Fragment;
        const routeKey = `Route-${i}`;
        return (
          <Route
            key={routeKey}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./components/NotFound')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: lazy(() => import('./pages')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signin',
    component: lazy(() => import('./pages/auth/signIn/SignIn')),
  },
  {
    guard: AuthGuard,
    layout: LayOut,
    path: '/app',
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('./pages/dashboard')),
      },
      {
        exact: true,
        path: '/app/settings',
        component: lazy(() => import('./pages/settings')),
      },
      {
        exact: true,
        path: '/app/profile',
        component: lazy(() => import('./pages/profile')),
      },
      {
        exact: true,
        path: '/app/appointments',
        component: lazy(() => import('./pages/appointments')),
      },
    ],
  },
  {
    path: '*',
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
