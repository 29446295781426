import { styled } from '@mui/system';
import { switchUnstyledClasses } from '@mui/material';

const iconsGrey = '#5c5c5c';
const boxBackground = '#fff';

export const NightSwitch = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 15px;
  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }
  & .${switchUnstyledClasses.track} {
    border: 1px solid ${iconsGrey};
    color: ${boxBackground};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
    border-radius: 14px;
    background-color: ${iconsGrey};
    position: relative;
    transition: all 200ms ease;
  }
  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${iconsGrey};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 27px;
      top: 3px;
      background-color: ${boxBackground};
    }
    .${switchUnstyledClasses.track} {
      background: ${iconsGrey};
    }
  }
  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export const SearchInputField = styled('input')`
  border: 0px none;
  box-sizing: content-box;
  padding: 4px 3px 5px 0px;
  cursor: text;
  font-size: 16px;
  width: 100%;
  &:hover {
    border: 0px none;
    outline: currentcolor none 0px;
  }
  &:focus {
    border: 0px none;
    outline: currentcolor none 0px;
  }
`;
