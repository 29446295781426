import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getAuthStore } from '../graphql/store';

const AuthGuard = ({ children }) => {
  const { accessToken } = getAuthStore();
  if (!accessToken) return <Redirect to="/" />;
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthGuard;
