import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import {
  getRefreshToken,
  setSession,
  isLoading,
  authStore,
  setLoading,
  setError,
  logout,
} from '../graphql/store';
import POST_REFRESH from '../graphql/querys/refreshToken';
import LoadingScreen from '../components/ui/LoadingScreen';

const AuthContext = ({ children }) => {
  const [firstTime, setFirstTime] = useState(true);
  const [refreshQuery, { loading, data, error }] = useLazyQuery(POST_REFRESH, {
    fetchPolicy: 'cache-and-network',
  });
  const reactiveAuthStore = useReactiveVar(authStore);
  const authToken = getRefreshToken();

  useEffect(() => {
    if (authToken && !reactiveAuthStore.accessToken && firstTime) {
      setFirstTime(false);
      refreshQuery({ variables: { refreshToken: authToken } });
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      const timeout = setTimeout(async () => {
        await refreshQuery({ variables: { refreshToken: authToken } });
      }, 55 * 60 * 1000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [reactiveAuthStore]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        const { accessToken, refreshToken } = data.refreshToken;
        const userId = data.refreshToken.data.uid;
        setSession({ accessToken, refreshToken, userId });
        setLoading(false);
      }
      if (error) {
        setError(true);
        setLoading(false);
        logout();
      }
    }
  }, [loading]);

  return <div>{isLoading() ? <LoadingScreen /> : children}</div>;
};

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
