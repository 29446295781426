import { azulTucar, rojoTucar, grisTucar } from './TucarColors';

const textfield = {
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        marginTop: '15px',
        marginBottom: '40px',
        height: '55px',
        '&.MuiOutlinedInput-root.Mui-error': {
          borderColor: rojoTucar,
        },
        '& .input:hover': {
          borderColor: azulTucar,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: azulTucar,
          },
          '& fieldset': {
            borderColor: grisTucar,
          },
          '&.Mui-focused.Mui-error fieldset': {
            borderColor: rojoTucar,
          },
          '&.Mui-error fieldset': {
            borderColor: rojoTucar,
          },
        },
      },
    },
  ],
};

export default textfield;
