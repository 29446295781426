import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/client';
import { getRefreshToken, logout, setLoading, isLoading } from '../../../graphql/store';
import POST_LOGOUT from '../../../graphql/mutations/signOut';

const LogOut = () => {
  const [desactivateToken] = useMutation(POST_LOGOUT);
  const [load, setLoad] = useState(false);
  const refreshToken = getRefreshToken();

  const history = useHistory();

  useEffect(async () => {
    if (load) {
      setLoading(false);
      try {
        await desactivateToken({ variables: { refreshToken } });
        logout();
        history.push('/signin');
      } catch (err) {
        logout();
        history.push('/signin');
      } finally {
        setLoad(false);
      }
    } else if (!isLoading()) {
      setLoading(true);
    }
  }, [load, isLoading]);

  return (
    <Button
      fullWidth
      data-testid="logOutButton"
      variant="outlined"
      sx={{
        color: '#0057b8',
        borderRadius: '50px',
        fontFamily: 'Poppins',
        fontWeight: '600',
        textTransform: 'initial',
      }}
      onClick={async (e) => {
        e.preventDefault();
        setLoad(true);
      }}
    >
      Cerrar sesión
    </Button>
  );
};

export default LogOut;
