/* eslint-disable import/no-mutable-exports */
import { ApolloClient, InMemoryCache, HttpLink, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  getRefreshToken,
  setSession,
  isExpired,
  setLoading,
  logout,
  getAuthStore,
} from '../graphql/store';
import POST_REFRESH from '../graphql/querys/refreshToken';
import POST_LOGOUT from '../graphql/mutations/signOut';

let client;

const refreshQuery = (currentRefreshToken) =>
  client.query({ query: POST_REFRESH, variables: { refreshToken: currentRefreshToken } });

const desactivateTokenQuery = (currentRefreshToken) =>
  client.mutate({ mutation: POST_LOGOUT, variables: { refreshToken: currentRefreshToken } });

const authMiddleware = setContext((_, { headers }) => {
  const currentRefreshToken = getRefreshToken();
  if (currentRefreshToken && isExpired()) {
    refreshQuery(currentRefreshToken)
      .then(({ data }) => {
        const { accessToken, refreshToken } = data.refreshToken;
        const userId = data.refreshToken.data.uid;
        setSession({ accessToken, refreshToken, userId });
        setLoading(false);
        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        };
      })
      .catch(() => {
        logout();
        desactivateTokenQuery(currentRefreshToken)
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
  }
  const { accessToken } = getAuthStore();
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const httpLink = new HttpLink({ uri: process.env.API });

client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
