import { createTheme } from '@mui/material/styles';

import './fonts.css';
import typography from './variants/MuiTypography';
import button from './variants/MuiButton';
import textfield from './variants/MuiTextField';
import tucarPalette from './variants/Palette';

const tucarTheme = createTheme({
  palette: tucarPalette,

  components: {
    MuiTypography: typography,
    MuiButton: button,
    MuiTextField: textfield,
  },
});

export default tucarTheme;
