import React from 'react';

const WhiteLogo = (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    data-testid="logoBlanco"
    className="logoPanel"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1279.1 372.4"
  >
    <defs>
      <style>{'.cls-1{fill:#fff;}'}</style>
    </defs>
    <path
      className="cls-1"
      d="M110.53,342.74a19.79,19.79,0,0,1-10.78-3.19A181.46,181.46,0,0,1,16.68,187a181.62,181.62,0,0,1,348.88-70.69A181.92,181.92,0,0,1,357,275.16a182.75,182.75,0,0,1-60.26,64.39,19.87,19.87,0,1,1-21.58-33.37A141.46,141.46,0,0,0,340.1,187c0-78.21-63.63-141.84-141.84-141.84S56.43,108.79,56.43,187a141.43,141.43,0,0,0,64.91,119.18,19.87,19.87,0,0,1-10.81,36.56Z"
    />
    <path
      className="cls-1"
      d="M57.26,201.8c-.18-1.66-.36-3.32-.48-5C56.9,198.48,57.09,200.14,57.26,201.8Z"
    />
    <path
      className="cls-1"
      d="M58,208.52l.1,0c-.12-.78-.26-1.54-.36-2.32C57.85,207,57.9,207.74,58,208.52Z"
    />
    <path className="cls-1" d="M56.43,187.35c0,.57.06,1.14.06,1.71,0-.63-.06-1.25-.06-1.89Z" />
    <path
      className="cls-1"
      d="M56.7,178.58c0-.11,0-.22,0-.34C56.72,178.36,56.71,178.47,56.7,178.58Z"
    />
    <path
      className="cls-1"
      d="M338.41,208.48l.1,0c.12-.78.16-1.57.27-2.36C338.67,206.94,338.53,207.7,338.41,208.48Z"
    />
    <path
      className="cls-1"
      d="M339.74,196.8c-.12,1.68-.3,3.34-.47,5C339.44,200.14,339.63,198.48,339.74,196.8Z"
    />
    <path
      className="cls-1"
      d="M266.35,191.32c21.16,2.4,39.53,5.89,53.75,10.13A123.61,123.61,0,0,0,321,187,122,122,0,0,0,308,132.26c-17.17,5-39.91,8.79-65.9,10.82A39.43,39.43,0,0,0,214,158.48l-.18.23a19.87,19.87,0,0,1-31.2-.12,39.87,39.87,0,0,0-28.23-15.51c-26-2-48.72-5.84-65.89-10.82A121.86,121.86,0,0,0,75.56,187a123.61,123.61,0,0,0,.87,14.45c14.22-4.24,32.58-7.73,53.74-10.13a43.37,43.37,0,0,1,48.22,43.12v14a19.87,19.87,0,0,0,39.74,0v-14A43.37,43.37,0,0,1,266.35,191.32Z"
    />
    <path
      className="cls-1"
      d="M340.1,187.35c0-.06,0-.12,0-.18,0,.64,0,1.26-.06,1.89C340,188.49,340.1,187.92,340.1,187.35Z"
    />
    <path
      className="cls-1"
      d="M339.8,178.24c0,.11,0,.23,0,.34C339.82,178.47,339.81,178.35,339.8,178.24Z"
    />
    <path
      className="cls-1"
      d="M571.31,164.9H534.88V232q0,8.39,4.19,12.25t12.25,4.36a178.94,178.94,0,0,0,20-.17v37.73q-42.88,4.83-60.45-8.06T493.28,232V164.9h-28v-40h28c0-46.75,41.6-45.14,41.6-45.14v45.14h36.43Z"
    />
    <path
      className="cls-1"
      d="M751.21,124.92h0V286.13H709.62V268.07q-15.17,22.57-48,22.57-26.45,0-43.69-17.73t-17.25-49v-99h41.59v93.82q0,16.13,8.71,24.67T674.15,252q16.13,0,25.79-10t9.68-30V166.51A41.59,41.59,0,0,1,751.21,124.92Z"
    />
    <path
      className="cls-1"
      d="M1084.65,148.83l-3.94-4.89q-18.7-23.52-52.55-23.53-32.25,0-55.3,24.66t-23.05,60.45q0,35.79,23.05,60.46t55.3,24.66q33.84,0,52.55-23.54v19h41.59V124.92A41.59,41.59,0,0,0,1084.65,148.83ZM1068,238.25Q1055.24,251,1035.89,251T1004,238.25q-12.57-12.74-12.57-32.73T1004,172.8q12.59-12.74,31.92-12.74T1068,172.8q12.73,12.73,12.74,32.72T1068,238.25Z"
    />
    <path
      className="cls-1"
      d="M1220.79,129.44q-13.38,7.73-19.18,23.21l-1.16,4.13A41.6,41.6,0,0,0,1160,124.93h0v161.2h41.59V209.07q0-23.2,15-33.21t34-7.74V121.7A58.47,58.47,0,0,0,1220.79,129.44Z"
    />
    <path
      className="cls-1"
      d="M866.18,290.66q-36.43,0-60.77-24.5t-24.35-60.61q0-36.11,24.35-60.62t60.77-24.5a83.58,83.58,0,0,1,42.88,11.28A75.11,75.11,0,0,1,938.4,162l-17.23,10.09a25.93,25.93,0,0,1-29-2.1,40.39,40.39,0,0,0-4.19-2.83,42.63,42.63,0,0,0-22.08-5.8q-18.71,0-31,12.41t-12.25,31.76q0,19,12.25,31.43t31,12.41a44.19,44.19,0,0,0,22.4-5.64,38.67,38.67,0,0,0,4.5-3,25.77,25.77,0,0,1,28.62-2.1L939,248.75a80.71,80.71,0,0,1-30,30.47A82.74,82.74,0,0,1,866.18,290.66Z"
    />
    <circle className="cls-1" cx="161.07" cy="344.82" r="19.84" />
  </svg>
);

export default WhiteLogo;
