import React from 'react';
import EventIcon from '@mui/icons-material/Event';

const sections = [
  {
    name: 'Agendamientos',
    icon: <EventIcon />,
    path: '/app/appointments',
    content: null,
  },
];

export default sections;
