import { gql } from '@apollo/client';

const GET_MECHANIC_INFORMATION = gql`
  query MechanicV1($mechanicId: ID!) {
    mechanicV1(mechanicId: $mechanicId) {
      info {
        email
        lastname
        firstname
      }
      status {
        enabled
        banned
      }
    }
  }
`;

export default GET_MECHANIC_INFORMATION;
