import { azulTucar } from './TucarColors';

const tucarPalette = {
  azulTucar: {
    main: azulTucar,
  },
  uberPalette: {
    main: '#000000',
    dark: '#3B3B3B',
  },
};

export default tucarPalette;
