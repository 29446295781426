import React from 'react';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

import client from './contexts/graphql';
import AuthContext from './contexts/AuthContext';
import routes, { renderRoutes } from './routes';
import tucar from './themes/Tucar';

const history = createBrowserHistory();

const App = () => (
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <HashRouter history={history}>
        <AuthContext>
          <ThemeProvider theme={tucar}>{renderRoutes(routes)}</ThemeProvider>
        </AuthContext>
      </HashRouter>
    </StylesProvider>
  </ApolloProvider>
);

export default App;
