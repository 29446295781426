import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';

import isotipoBlanco from '../../../components/logo/WhiteIsotype';
import logoBlanco from '../../../components/logo/WhiteLogo';
import nestedList from './nestedList';
import './style.css';

const NavBar = (props) => {
  const { open, handleOpen, tinyMode } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ justifyContent: 'center', display: 'fixed' }}>
        {open ? logoBlanco : isotipoBlanco}
      </Box>
      <Box className="contentSide">
        <Box>{nestedList(tinyMode ? true : open)}</Box>
        <IconButton sx={{ position: 'absolute', bottom: '20px' }} onClick={handleOpen}>
          {open ? (
            <ChevronLeftRounded style={{ fontSize: '24px' }} className="icon" />
          ) : (
            <ChevronRightRounded style={{ fontSize: '24px' }} className="icon" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  tinyMode: PropTypes.bool,
};

NavBar.defaultProps = {
  tinyMode: false,
};

export default NavBar;
