import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import LogOut from '../../../../components/auth/LogOut';
import './index.css';

const AccountTool = ({ userInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickOpenPopoverHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const userName = `${userInfo.firstname} ${userInfo.lastname}`;
  const userEmail = userInfo.email;

  return (
    <>
      <Box className="icon-wrapper">
        <IconButton onClick={onClickOpenPopoverHandler}>
          <AccountCircleIcon style={{ fontSize: '35px' }} />
        </IconButton>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ marginTop: '10px', width: '235px' }}
        className="account-tool-popover"
      >
        <Box
          style={{
            padding: '25px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div className="user-image">
            <AccountCircleIcon style={{ width: '100%', height: '105px' }} />
          </div>
          <div className="user-information">
            <Typography
              style={{ padding: '20px 0', color: '#0057b8', fontSize: '16px' }}
              className="PoppinsSemiBold"
            >
              {userName}
            </Typography>
            <Typography
              style={{ paddingBottom: '25px', fontSize: '14px' }}
              className="PoppinsRegular"
            >
              {userEmail}
            </Typography>
          </div>
          <LogOut />
        </Box>
      </Popover>
    </>
  );
};

AccountTool.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccountTool;
