import React from 'react';
import PropTypes from 'prop-types';
import { Box, AppBar, ClickAwayListener } from '@mui/material';

import NavBar from './navbar';
import TopBar from './topbar';
import './style.css';

const NormalLayout = ({ open, setOpen, handleOpen, children, userInfo }) => {
  return (
    <Box>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box className={open ? 'slideBar' : 'slideBarClosed'}>
          <NavBar open={open} handleOpen={handleOpen} />
        </Box>
      </ClickAwayListener>
      <Box>
        <AppBar className={open ? 'topBar' : 'topBarClosed'}>
          <TopBar userInfo={userInfo} handleOpen={setOpen} />
        </AppBar>
        <Box className={open ? 'content' : 'contentClosed'}>
          <Box sx={{ padding: '40px 30px' }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

NormalLayout.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
  }).isRequired,
};

NormalLayout.defaultProps = {
  children: <div />,
};
export default NormalLayout;
