import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box, useMediaQuery } from '@mui/material';

import { getAuthStore, setLoading, isLoading } from '../../graphql/store';
import GET_MECHANIC_INFORMATION from '../../graphql/querys/mechanicInfo';

import NormalLayout from './normalLayout';
import TinyLayout from './tinyLayout';
import './style.css';

const LayOut = ({ children }) => {
  const matches = useMediaQuery('(max-width: 1024px)');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(children);
  const { userId } = getAuthStore();
  const { loading, error, data } = useQuery(GET_MECHANIC_INFORMATION, {
    variables: { mechanicId: userId },
  });

  useEffect(() => {
    if (!loading) {
      setLoading(false);
      if (data) {
        if (data.mechanicV1.status.enabled === false || data.mechanicV1.status.banned) {
          setContent(
            <div className="PoppinsRegular">
              Tu cuenta actualmente se encuentra inhabilitada por lo que no puedes acceder a esta
              información
            </div>,
          );
        }
      }
    } else if (!isLoading()) {
      setLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    if (matches) {
      setOpen(false);
    }
  }, [matches]);

  const handleOpen = () => {
    setOpen(!open);
  };

  if (loading) return <div />;
  if (error) return <div>{error}</div>;

  return (
    <Box className="root">
      {!matches ? (
        <NormalLayout
          matches={matches}
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          userInfo={data.mechanicV1.info}
        >
          {content}
        </NormalLayout>
      ) : (
        <TinyLayout
          matches={matches}
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          userInfo={data.mechanicV1.info}
        >
          {content}
        </TinyLayout>
      )}
    </Box>
  );
};

LayOut.propTypes = {
  children: PropTypes.element,
};

LayOut.defaultProps = {
  children: <div />,
};
export default LayOut;
