import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getAuthStore } from '../graphql/store';

const GuestGuard = ({ children }) => {
  const { accessToken } = getAuthStore();
  if (accessToken) return <Redirect to="/app/appointments" />;
  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.element,
};

GuestGuard.defaultProps = {
  children: <div />,
};

export default GuestGuard;
