import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import sections from './sections';
import './style.css';

const nestedList = (displayText) => {
  const [open, setOpen] = useState({});

  const handleClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const components = sections.flatMap((item) =>
    item.content ? (
      <Box key={`${item.name}`}>
        <ListItemButton sx={{ height: '45px' }} onClick={() => handleClick(item.name)}>
          <ListItemIcon className={!open[item.name] ? 'selected' : 'unSelected'}>
            {item.icon}
          </ListItemIcon>
          {displayText ? (
            <ListItemText
              className={!open[item.name] ? 'selected' : 'unSelected'}
              primary={item.name}
            />
          ) : null}
          {open[item.name] ? <ExpandLess className="icon" /> : <ExpandMore className="icon" />}
        </ListItemButton>
        <Collapse in={open[item.name]} timeout="auto" unmountOnExit>
          <List component="div" sx={{ fontFamily: 'Poppins' }} disablePadding>
            {item.content.flatMap((elem) => (
              <NavLink key={`${elem.name}`} to={elem.path} style={{ textDecoration: 'none' }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>{elem.icon}</ListItemIcon>
                  {displayText ? <ListItemText secondary={elem.name} /> : null}
                </ListItemButton>
              </NavLink>
            ))}
          </List>
        </Collapse>
      </Box>
    ) : (
      <NavLink
        activeClassName="unSelected"
        key={`${item.name}`}
        to={item.path}
        style={{ textDecoration: 'none' }}
      >
        <ListItem button sx={{ height: '45px' }}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          {displayText ? <ListItemText primary={item.name} /> : null}
        </ListItem>
      </NavLink>
    ),
  );
  return <List>{components}</List>;
};

export default nestedList;
