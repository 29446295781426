import React from 'react';

const isotipoBlanco = (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    data-testid="logoBlancoSmall"
    className="logoPanelSmall"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 368 364.04"
  >
    <defs>
      <style>{'.cls-1{fill:#fff;}'}</style>
    </defs>
    <path
      className="cls-1"
      d="M96.26,339.72a19.78,19.78,0,0,1-10.77-3.19A181.49,181.49,0,0,1,2.42,184a181.52,181.52,0,0,1,310-128.39,181.64,181.64,0,0,1,30.38,216.55,182.87,182.87,0,0,1-60.26,64.4,19.87,19.87,0,1,1-21.59-33.37A141.45,141.45,0,0,0,325.83,184c0-78.21-63.62-141.83-141.83-141.83S42.16,105.76,42.16,184a141.46,141.46,0,0,0,64.92,119.19,19.87,19.87,0,0,1-10.82,36.56Z"
    />
    <path
      className="cls-1"
      d="M43,198.78c-.17-1.67-.36-3.33-.47-5C42.63,195.45,42.82,197.11,43,198.78Z"
    />
    <path
      className="cls-1"
      d="M43.75,205.5l.1-.05c-.12-.77-.26-1.54-.37-2.31C43.59,203.92,43.63,204.72,43.75,205.5Z"
    />
    <path
      className="cls-1"
      d="M42.16,184.32c0,.58.06,1.14.07,1.72,0-.63-.06-1.26-.06-1.89C42.17,184.2,42.16,184.26,42.16,184.32Z"
    />
    <path className="cls-1" d="M42.43,175.56l0-.35Z" />
    <path
      className="cls-1"
      d="M324.15,205.45l.1.05c.11-.78.16-1.58.26-2.36C324.41,203.91,324.27,204.68,324.15,205.45Z"
    />
    <path
      className="cls-1"
      d="M325.48,193.77c-.12,1.68-.3,3.34-.48,5C325.17,197.11,325.36,195.45,325.48,193.77Z"
    />
    <path
      className="cls-1"
      d="M252.09,188.29c21.16,2.4,39.52,5.89,53.74,10.13A123.61,123.61,0,0,0,306.7,184a121.89,121.89,0,0,0-12.93-54.74c-17.17,5-39.91,8.79-65.9,10.83a39.43,39.43,0,0,0-28.15,15.4l-.18.23a19.84,19.84,0,0,1-31.19-.13,39.89,39.89,0,0,0-28.23-15.5c-26-2-48.73-5.85-65.9-10.83A122,122,0,0,0,61.29,184a123.61,123.61,0,0,0,.87,14.45c14.22-4.24,32.59-7.73,53.75-10.13a43.38,43.38,0,0,1,48.22,43.12v14a19.87,19.87,0,0,0,39.74,0v-14A43.38,43.38,0,0,1,252.09,188.29Z"
    />
    <path
      className="cls-1"
      d="M325.83,184.32v-.17c0,.63-.05,1.26-.06,1.89C325.77,185.46,325.83,184.9,325.83,184.32Z"
    />
    <path className="cls-1" d="M325.53,175.21l0,.35Z" />
    <circle className="cls-1" cx="146.81" cy="341.8" r="19.84" />
  </svg>
);

export default isotipoBlanco;
