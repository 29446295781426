import globalStore from './model';

export const isLoading = () => {
  const { loading } = globalStore();
  return loading;
};

export const error = () => {
  const globalStoreData = globalStore();
  return globalStoreData.error;
};
