import { gql } from '@apollo/client';

const POST_REFRESH = gql`
  query RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      data {
        uid
      }
    }
  }
`;

export default POST_REFRESH;
