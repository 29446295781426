import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dehaze, Search, Notifications, Settings } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Badge,
  SwitchUnstyled,
  Toolbar,
  InputUnstyled,
  Slide,
  Button,
  ClickAwayListener,
} from '@mui/material';

import { NightSwitch, SearchInputField } from './styles';
import AccountTool from './accountTool';
import './style.css';

const TopBar = ({ handleOpen, userInfo }) => {
  const notificationsNumber = 4;
  const [isSearchBarActive, setIsSearchBarActive] = useState(false);
  const showSearchBar = () => {
    setIsSearchBarActive(true);
    handleOpen(false);
  };
  const hideSearchBar = () => setIsSearchBarActive(false);

  const handleClickNav = () => {
    handleOpen((prevState) => !prevState);
  };

  return (
    <ClickAwayListener onClickAway={hideSearchBar}>
      <Box>
        <Slide direction="down" in={isSearchBarActive} mountOnEnter unmountOnExit>
          <Box className="search-bar-wrapper" onFocus={showSearchBar}>
            <Box className="search-bar">
              <Search className="icon" />
              <InputUnstyled
                autoFocus
                components={{ Input: SearchInputField }}
                type="text"
                placeholder="Buscar"
                className="text-field PoppinsRegular"
              />
            </Box>
            <Button
              variant="outlined"
              className="lined"
              onClick={hideSearchBar}
              sx={{ fontSize: '12px', height: '30px', marginRight: '30px' }}
            >
              Buscar
            </Button>
          </Box>
        </Slide>

        <Toolbar className="toolBar">
          <Box className="leftSide">
            <IconButton className="temporal" onClick={handleClickNav}>
              <Dehaze className="menu" />
            </IconButton>
            <IconButton className="left-Icon" onClick={showSearchBar}>
              <Search />
            </IconButton>
          </Box>

          <Box className="rightSide">
            <SwitchUnstyled component={NightSwitch} />

            <IconButton className="right-Icon space">
              <Settings />
            </IconButton>

            <IconButton className="right-Icon">
              <Badge badgeContent={notificationsNumber} color="error">
                <Notifications />
              </Badge>
            </IconButton>

            <AccountTool userInfo={userInfo} />
          </Box>
        </Toolbar>
      </Box>
    </ClickAwayListener>
  );
};

TopBar.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
  }).isRequired,
};

export default TopBar;
