import { authStore } from './model';

const getExpirationDate = () => new Date().getTime() + 7 * 24 * 60 * 60 * 1000 - 5 * 60 * 1000;

export const setExpirationDate = () => localStorage.setItem('expirationDate', getExpirationDate());

export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);

export const setSession = ({ refreshToken, accessToken, userId }) => {
  setRefreshToken(refreshToken);
  setExpirationDate();
  authStore({ ...authStore(), accessToken, userId });
};

export const logout = () => {
  localStorage.clear();
  authStore({ ...authStore(), accessToken: null, userId: null });
};
