import { azulTucar, celesteTucar } from './TucarColors';

const typography = {
  variants: [
    {
      props: { variant: 'h1' },
      style: {
        textAlign: 'center',
        fontSize: 45,
        fontFamily: 'Gilroy-Bold',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'remark' },
      style: {
        color: azulTucar,
        textAlign: 'center',
        fontSize: 16,
        fontFamily: 'Poppins',
        fontWeight: '600',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'remark', type: 'link' },
      style: {
        color: celesteTucar,
      },
    },
    {
      props: { variant: 'text' },
      style: {
        color: '#000000',
        fontSize: 16,
        fontFamily: 'Poppins',
        fontWeight: '400',
        textAlign: 'center',
        letterSpacing: 0,
      },
    },
    {
      props: { variant: 'p' },
      style: {
        color: '#000000',
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: '300',
        letterSpacing: 0,
      },
    },
  ],
};

export default typography;
