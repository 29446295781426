const button = {
  variants: [
    {
      props: { type: 'submit' },
      style: {
        height: '55px',
        borderRadius: 50,
        color: '#FFFFFF',
        fontFamily: 'Poppins',
        fontWeight: '600',
        textTransform: 'initial',
      },
    },
    {
      props: { className: 'uber' },
      style: {
        marginTop: '50px',
        height: '55px',
        borderRadius: '50px',
        backgroundColor: '#000000',
        color: '#FFFFF',
        fontFamily: 'Poppins',
        fontWeight: '600',
        textTransform: 'initial',
      },
    },
    {
      props: { className: 'lined' },
      style: {
        borderRadius: '50px',
        fontFamily: 'Poppins',
        fontWeight: '600',
        textTransform: 'initial',
      },
    },
  ],
};

export default button;
